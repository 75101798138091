export const isSafariIframe = () => {
    return new Promise( function(resolve, reject) {
      function isIframe(){
          return new Promise( function( resolve, reject ){
              if( window.self !== window.top ){
                  resolve(true)
              }else{
                  reject(new Error('iframeFalse'))
              }
          })
      }

      function checkBrowser(){
          return new Promise( function( resolve, reject ){
              var ua = navigator.userAgent.toLowerCase()
              if( ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1){
                  //on safari
                  resolve('safari')
              }else{
                  resolve('other')
              }
          })
      }

      function checkCookies(){
          return new Promise( function(resolve, reject){
              document.cookie = 'testCookie=true; samesite=none; secure'
              if( !navigator.cookieEnabled || !document.cookie.includes("testCookie=true") ) {
                    reject(new Error('cookiesDisabled'))
                }else{
                    console.log("cookie is ", document.cookie)
                    resolve( true )
                }
          })
      }

      function checkSession(){
          return new Promise( function( resolve, reject ){
              var xhr = new XMLHttpRequest();
              xhr.open('GET', '/getIsSafariIframed')
              xhr.onload = function(){
                  var res = JSON.parse(xhr.responseText)
                  if( !res.isIframed ){
                      resolve(true)
                  }else{
                      reject(new Error('sessionSet'))
                  }
              }
              xhr.send();
          })
      }

    //Trigger a post message to the parent window
    //Parent window will run redirect code as long as it's including
    //<script src="https://cdn.floify.com/whitelabel.min.js">
      function handleRedirect(){
        let intervalNum = 0
        return new Promise( function( resolve, reject) {
          if (document.referrer.indexOf('/n/apply') > -1) {
            resolve(true)
          } else {
            let myInterval = setInterval(() => {
                intervalNum += 1
                if (intervalNum > 50) {
                    clearInterval(myInterval);
                    console.error("You are missing a JavaScript file to display an iframed Floify site. Please contact your site administrator.")
                }
                window.parent.postMessage("Trigger Redirect", '*');
            }, 200)
          }

      })
    }

      function showError(e){
        if( e != 'Error: sessionSet'){
            //It looks like cross-site tracking is disabled or you’re using private browsing, which will prevent you from applying in this tab. To enable cross-site tracking, go to Safari Preferences > Privacy > uncheck Prevent cross-site tracking. If that doesn’t work, click here to apply in a new tab.
            var divToPrepend = document.createElement("div");
            var iframeURL = window.location.href
            var typeOfView = iframeURL.includes("login") ? "logging in" : iframeURL.includes("apply") ? "applying" : "logging in or applying"
            var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
            var linkText = iframeURL.includes("login") ? "login" : iframeURL.includes("apply") ? "apply" : "sign in or apply"
            var safariText = "To enable cross-site tracking, go to Safari Preferences > Privacy > uncheck <i>Prevent Cross-Site Tracking</i>. If that doesn’t work, <a href=" + iframeURL + " target='_blank'>click here</a> to " + linkText +" in a new tab."
            var chromeText = "To enable cross-site tracking, go to Chrome Settings > Cookies and other site data > select <i>Allow all cookies</i>. If that doesn’t work, <a href=" + iframeURL + " target='_blank'>click here</a> to " + linkText +" in a new tab."
            divToPrepend.style.cssText = "width:100%; z-index:999; padding: 20px; font-size: 18px; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; position: relative;"
            divToPrepend.innerHTML = "It looks like you're preventing cross-site tracking or using private browsing, which will prevent you from " + typeOfView + " in this tab." + ( isSafari ? safariText : chromeText )

            document.body.insertBefore(divToPrepend, document.body.firstChild);


        }
      }

      isIframe()
      .then( function( res ){
          if( res ){
              return checkBrowser()
          }
      })
      .then( function( res ){
          if( res === 'safari'){
              checkCookies()
              .then( function( res ){
                  if( res ){
                      return checkSession()
                  }
              })
              .catch(function(e) {showError(e) })
              .then( function( res ){
                  if( res ){
                    return handleRedirect()
                  }
              })
              .then(function(res) {
                setTimeout(() => {
                  resolve(true)
                }, 200);
              })
              .catch( function(e){ showError(e) } )
          }else{
              checkCookies()
              .then( function( res ){
                  //do nothing
                  resolve(true)
              })
              .catch( function(e){ showError(e) } )
              resolve(true)
          }

      })


      .catch( function(e){
        resolve(true)
      })



  })

   }